<template>
  <v-container
    fluid
    fill-height
  >
    <v-menu
      open-on-hover
      bottom
      right
      offset-y
      transition="slide-y-transition"
      style="position: absolute; right: 0; top: 0; padding: 15px;"
    >
      <router-link
        v-ripple
        slot="activator"
        class="toolbar-items"
        to=""
      >
        <v-badge
          color="error"
          overlap
        >
          <span style="text-transform: uppercase; color: rgba(0,0,0,.87);">{{ selectedLocale.locale }}</span>
        </v-badge>
      </router-link>
      <v-card>
        <v-list dense>
          <v-list-tile
            v-for="language in languages"
            :key="language.locale"
            @click="changeLanguage(language)"
          >
            <v-list-tile-title
              v-text="$t(language.name)"
            />
          </v-list-tile>
        </v-list>
      </v-card>
    </v-menu>
    <v-layout
      align-center
      justify-center
    >
      <v-flex
        xs12
        sm8
        md4
      >
        <v-card class="elevation-12">
          <v-toolbar
            :color="'primary'"
            flat
          >
            <v-toolbar-title style="text-align: center;">
              <!-- <img
                v-if="forConasi"
                src="../../assets/conasi_logo.png"
                width="50%"> -->
              <img
                src="../../assets/StockManager.png"
                width="50%"
                style="width: 100%; max-width: 200px; padding-top: 5px">
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-text-field
                :rules="[ requiredValue ]"
                v-model="username"
                :label="$t('login.username')"
                :name="$t('login.username')"
                prepend-icon="mdi-account"
                type="text"
                class="required"
                @keyup.enter="goToDashboard($event)"
              />
              <v-text-field
                id="password"
                :rules="[ requiredValue ]"
                v-model="password"
                :label="$t('login.password')"
                :name="$t('login.password')"
                prepend-icon="mdi-lock"
                type="password"
                class="required"
                @keyup.enter="goToDashboard($event)"
              />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <span @click="onGoResetPassword"><a>{{ $t('login.forgotPassword') }}</a></span>
            <v-btn
              color="success"
              @click="goToDashboard($event)">{{ $t('login.login') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
    <loading-bar :is-loading="isLoading"/>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar" />
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import functionUtils from 'utils/functionUtils'
import stringUtils from 'utils/stringUtils'
import ToastType from 'enum/toastType'

export default {
  data: () => ({
    valid: true,
    username: '',
    password: '',
    isLoading: false
  }),
  computed: {
    ...mapGetters(['GET_SNACK_BAR', 'languages', 'selectedLocale'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    /**
     * Go reset password
     */
    onGoResetPassword: function () {
      this.$router.push({
        path: 'forgot-password'
      })
    },
    requiredValue (value) {
      if (value !== null && value !== undefined && value.length !== 0) {
        return true
      }
      return this.$t('common.requiredFields')
    },
    /**
     * Change language
     */
    changeLanguage (language) {
      this.$i18n.locale = language.locale
      this.$store.dispatch('changeLanguage', language)
    },
    validate () {
      this.$router.push({
        path: '/dashboard'
      })
      if (this.$refs.form.validate()) {
        this.snackbar = true
        let filter = {
          username: this.username.trim().toLowerCase(),
          password: this.password
        }
        this.isLoading = true
        this.LOGIN(filter).then(
          function (res) {
            this.isLoading = false
            this.$router.push({
              path: '/dashboard'
            })
          }.bind(this)
        ).catch(
          function (error) {
            this.isLoading = false
            if (error.message === stringUtils.NETWORK_ERROR) {
              this.ON_SHOW_TOAST({
                message: this.$t('common.networkError'),
                styleType: ToastType.ERROR
              })
            } else if (!functionUtils.isNull(error.response.data.message)) {
              if (error.response.status === 401) {
                this.ON_SHOW_TOAST({
                  message: this.$t('login.loginInvalid'),
                  styleType: ToastType.ERROR
                })
              } else {
                this.ON_SHOW_TOAST({
                  message: this.$t(error.response.data.message),
                  styleType: ToastType.ERROR
                })
              }
            } else {
              this.ON_SHOW_TOAST({
                message: this.$t('login.somethingWentWrong'),
                styleType: ToastType.ERROR
              })
            }
          }.bind(this)
        )
      }
    },
    /**
     * Go dashboard
     */
    goToDashboard: function (event) {
      event.preventDefault()
      this.validate()
      this.$router.push({
        path: '/dashboard'
      })
    },
    ...mapActions(['LOGIN', 'ON_SHOW_TOAST'])
  }
}
</script>
<style lang="scss" scoped>
.translate-toolbar .translate-toolbar__content{
  justify-content: flex-end !important;
  float: right;
}
/deep/.required label::after {
  content: " *";
  color: red;
}
.v-toolbar .v-toolbar__content .v-toolbar__items .toolbar-items:hover {
  background: #e5e5e5;
}
.v-card__actions {
  display: flex;
  justify-content: space-between;
}
</style>
